import React from 'react'
import Layout from '../components/layout'
import DonationSuccess from '../components/donationSuccess'
import Seo from '../components/seo'

function ThankYou() {
  return (
    <Layout bodyClass="donation-success">
      <Seo title="Thank you for your donation." />
      <DonationSuccess />
    </Layout>
  )
}

export default ThankYou
