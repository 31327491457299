import { Link } from "gatsby"
import React from "react"
import { MdArrowBack } from "@react-icons/all-files/md/MdArrowBack"
import Thanks from '../../assets/images/svgs/thanks.svg'

import './style.scss'
const DonationSuccess = () => {
  return (
    <section className="container">
      <div className="thank-you">
        <Thanks aria-label="A lady carrying two heart shapes in orange and green" />
        <h1>Thank you for your support.</h1>
        <p>Your donation is successfully paid to AWRA, your support is highly apperciated!</p>
        <footer className="-footer buttons">
          <Link to="/donate" className="button is-rounded is-primary">
            <span className="icon">
              <MdArrowBack />
            </span>
            <span>One More Donation</span></Link>
          <Link to="/blog" className="button is-rounded ">Our Articles</Link>
        </footer>
      </div>
    </section>
  )
}

export default DonationSuccess
